import React from 'react';
import './Saved.css';

function Saved(){

  return (
    <button className="save-button saved" disabled>Book Saved</button>
  )
};

export default Saved;